import React from "react"
import { Link } from "gatsby"

export const CustomButton = ({ className, text, link, navLink }) => {
  return (
    <>
      {link && (
        <a href={link} className={`${className} whitespace-nowrap text-15`}>
          {text}
        </a>
      )}
      {navLink && (
        <Link to={navLink} className={`${className} whitespace-nowrap text-15`}>
          {text}
        </Link>
      )}
    </>
  )
}
