import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { CustomButton } from "../../components/common/CustomButton"
import HeaderLogo from "../../assets/header/headerLogo.svg"
import { Link } from "gatsby"
import FooterLogo from "../../assets/footer/footerLogo.svg"
import HeroImg from "../../images/2-minute-quiz/heroImg.webp"

const TwoMinuteQuizPage = () => {
  const quizUrl = "https://mhcquiz.typeform.com/to/fS9yRWeG"

  return (
    <>
      <GatsbySeo
        title="Take Our 2 Minute ED & PE Assessment Quiz | Men's Health Clinic"
        description="Unsure if you have ED or PE? Take our quick 2-minute assessment quiz developed by our leading doctors to understand your symptoms."
        noindex={true}
        nofollow={true}
      />

      <div className="relative flex min-h-screen flex-col overflow-hidden">
        <header className="px-6 pt-6">
          <div className="mx-auto max-w-7xl">
            <div className="flex items-center justify-between">
              <Link to={`/ca`}>
                <HeaderLogo className="w-[75px]" />
              </Link>
              <CustomButton
                className="orangeFilled slim__btn"
                text="Take action"
                link={quizUrl}
              />
            </div>
          </div>
        </header>

        <main className="width__container flex flex-1 py-60">
          <div className="max-w-2xl text-white">
            <p className="header__image--dash-text max680:ml-[50%] max680:translate-x-[-50%] max680:p-0 max680:text-center max680:before:hidden">
              MEN&apos;S HEALTH CLINIC
            </p>

            <h1 className="header__image--title home__header--img-title max680:text-center">
              Take Our 2 Minute Quiz
            </h1>

            <p className="header__image--text home__header-image--text">
              Unsure if you have ED or PE? Take this quiz to get an assessment
              of your symptoms.
            </p>

            <CustomButton
              className="orangeFilled slim__btn max680:ml-[50%] max680:translate-x-[-50%]"
              text="I need to enhance my erection"
              link={quizUrl}
            />
          </div>
        </main>

        <div
          className="absolute inset-0 z-[-1] bg-cover bg-center"
          style={{
            backgroundImage: `linear-gradient(
              180deg,
              rgba(3, 6, 84, 0.2),
              rgba(3, 6, 84, 0.2)
            ),
            linear-gradient(90deg, #353876, transparent),
            url(${HeroImg})`,
          }}
        />

        <footer className="mt-auto border-t border-white/10 bg-030654 py-6 py-60">
          <div className="width__container">
            <div className="flex items-center justify-between pb-30 max600:flex-col max600:gap-y-20">
              <Link to={`/ca`}>
                <FooterLogo className="h-[45px]" />
              </Link>
              <CustomButton
                className="orangeFilled slim__btn"
                text="Start Assessment"
                link={quizUrl}
              />
            </div>
            <hr />
            <div className="flex flex-col items-center justify-between gap-6 py-4 sm:flex-row">
              <div className="flex gap-6">
                <Link
                  to="/ca/privacy-policy"
                  className="text-sm text-[#f5f5f5] hover:text-white"
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/ca/terms-conditions"
                  className="text-sm text-[#f5f5f5] hover:text-white"
                >
                  Terms & Conditions
                </Link>
              </div>

              <div className="text-sm text-[#f5f5f5]">
                © MHC {new Date().getFullYear()}. All Rights Reserved.
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default TwoMinuteQuizPage
